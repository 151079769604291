import React, { useEffect, useState } from 'react';
import { Button, useMediaQuery } from '@mui/material';
import '../../pages/DashboardPage.css';
import defaultNoEventImage from '../../images/defaultNoEventImage.png';

function EventCard({ event, cardWidth, cardHeight }) {
  const [logoImage, setLogoImage] = useState(defaultNoEventImage);
  const isMobile = useMediaQuery('(max-width: 600px)'); // Detect if we're on a mobile view

  useEffect(() => {
    // Set the logo image or default if none is provided
    if (event.logoUrl) {
      setLogoImage(event.logoUrl);
    }
  }, [event.logoUrl]);

  // Format epoch time into "MMM d yyyy" format
  const formatDate = (epochTime) => {
    const options = { month: 'short', day: 'numeric' };
    const date = new Date(Number(epochTime));
    return `${date.toLocaleDateString('en-US', options)} ${date.getFullYear()}`;
  };

  return (
    <Button
      className='dashboard-card-container'
      style={{
        width: cardWidth,
        height: cardHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid #cccccc',
        padding: '16px',
        cursor: 'pointer',
        textTransform: 'none', // Prevent uppercase text in the button
        transition: 'background-color 0.3s, box-shadow 0.3s',
        marginBottom: isMobile ? '16px' : '0px', // Add spacing on mobile
      }}
      onClick={() => {
        console.log(`Event clicked: ${event.eventTitle}`);
        // Add navigation or action here
      }}
      onMouseOver={(e) => {
        e.currentTarget.style.backgroundColor = "#f0f0f0"; // Hover effect
        e.currentTarget.style.boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.2)";
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.backgroundColor = "#fff"; // Reset on hover out
        e.currentTarget.style.boxShadow = "none";
      }}
    >
      <img
        src={logoImage}
        alt={event.eventTitle}
        style={{
          width: '70%',
          height: '140px',
          objectFit: 'contain',
          marginBottom: '16px',
        }}
      />
      <h3 style={{ margin: '8px 0', color: '#333', fontSize: '18px' }}>{event.eventTitle}</h3> {/* Increased font size */}
      <h4 style={{ margin: '4px 0', color: '#666' }}>
        {formatDate(event.eventStartDateTimeGMT)} - {formatDate(event.eventEndDateTimeGMT)}
      </h4>
    </Button>
  );
}

export default EventCard;

