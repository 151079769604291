import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Dialog, DialogContent, DialogTitle, IconButton, Tabs, Tab, Box, Button, Stack, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateTimePicker } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { SketchPicker } from 'react-color';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Function to render the content for each tab
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// Helper function for accessibility props
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CreateEventModal = (props) => {
  
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile

  // STATE - General
  const [eventTitle, setEventTitle] = useState('');
  const [tier, setTier] = useState('Tier 1');
  const [backgroundColor, setBackgroundColor] = useState("gray");
  const [headlineTextColor, setHeadlineTextColor] = useState("black");
  const [subheadlineTextColor, setSubheadlineTextColor] = useState("black");
  const [backgroundColorDialogOpen, setBackgroundColorDialogOpen] = useState(false);
  const [headlineTextColorDialogOpen, setHeadlineTextColorDialogOpen] = useState(false);
  const [subheadlineTextColorDialogOpen, setSubheadlineTextColorDialogOpen] = useState(false);
  const [homepageSubheadline, setHomepageSubheadline] = useState();
  const [eventLogo, setEventLogo] = useState();
  const [eventStartDateTime, setEventStartDateTime] = useState(null);
  const [eventEndDateTime, setEventEndDateTime] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);  // Track if form is being submitted
  const [isSubmitted, setIsSubmitted] = useState(false);    // Track if form was successfully submitted

  // STATE - Teams
  const [defaultTeamFields, setDefaultTeamFields] = useState([
    { field: 'Team email', type: 'Email', required: true },
    { field: 'Team phone', type: 'Phone', required: true },
    { field: 'Hometown', type: 'Text', required: false },
  ]);
  const [customTeamFields, setCustomTeamFields] = useState([]);

  // STATE - Catches
  const [catchRows, setCatchRows] = useState([{ species: '', scoring: 'Catch & Release', points: 0 }]);

  // FETCH
  const handleCreateEventAsAdmin = async () => {
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === "staging"
        ? process.env.REACT_APP_SERVER_URL_STAGING
        : process.env.REACT_APP_SERVER_URL_PRODUCTION;
  
      const response = await fetch(`${apiUrl}/api/create_event_in_firebase`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userName: props.userName,  // Include userName in the request
          eventTitle,
          tier,
          backgroundColor,
          headlineTextColor,
          subheadlineTextColor,
          eventStartDateTime,
          eventEndDateTime,
          homepageSubheadline,
          catchRows,
          teamFields: {
            default: defaultTeamFields,
            custom: customTeamFields,
          },
          eventLogoBase64: eventLogo ? await getBase64(eventLogo) : null,  // Convert image to base64
        }),
      });
  
      if (response.ok) {
        toast.success("Event created successfully.");
        handleClose();  // Close modal on success
      } else {
        toast.error("Error creating event.");
      }
    } catch (error) {
      console.error("Error creating event:", error);
      toast.error("Error creating event.");
    }
  };
  
  // HELPERS
  const delayRefresh = () => {
    setTimeout(() => {
      console.log('Delaying page refresh...');
      window.location.reload();
    }, 2000);
  };

  const handleOpenDialog = (setDialogOpen) => () => setDialogOpen(true);
  const handleCloseDialog = (setDialogOpen) => () => setDialogOpen(false);

  const handleLogoUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      setEventLogo(e.target.files[0]);
    }
  };

  const handleRemoveLogo = () => {
    setEventLogo(null);
    document.getElementById(`event-logo-upload-button`).value = '';
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const updateDefaultTeamField = (index, key, value) => {
    const updatedFields = [...defaultTeamFields];
    updatedFields[index][key] = value;
    setDefaultTeamFields(updatedFields);
  };

  const updateCustomTeamField = (index, key, value) => {
    const updatedFields = [...customTeamFields];
    updatedFields[index][key] = value;
    setCustomTeamFields(updatedFields);
  };

  const addCustomTeamField = () => {
    setCustomTeamFields([...customTeamFields, { field: '', type: 'Text', required: false }]);
  };

  const handleRemoveDefaultTeamField = (index) => {
    const updatedFields = [...defaultTeamFields];
    updatedFields.splice(index, 1);
    setDefaultTeamFields(updatedFields);
  };

  const handleRemoveCustomTeamField = (index) => {
    const updatedFields = [...customTeamFields];
    updatedFields.splice(index, 1);
    setCustomTeamFields(updatedFields);
  };

  const handleAddCatchRow = () => {
    setCatchRows([...catchRows, { species: '', scoring: 'Catch & Release', points: 0 }]);
  };

  const handleCatchAttributeChange = (e, index, attribute) => {
    const newCatchRows = [...catchRows];
    newCatchRows[index][attribute] = e.target.value;
    setCatchRows(newCatchRows);
  };

  const handleRemoveCatchRow = (index) => {
    const newCatchRows = catchRows.filter((_, i) => i !== index);
    setCatchRows(newCatchRows.length > 0 ? newCatchRows : [{ species: '', scoring: 'Catch & Release', points: 0 }]);
  };

  const handleClose = () => {
    setActiveTab(0);
    setEventTitle('');
    setTier('');
    setBackgroundColor('gray');
    setHeadlineTextColor('black');
    setSubheadlineTextColor('black');
    setEventStartDateTime(null);
    setEventEndDateTime(null);
    setHomepageSubheadline('');
    setEventLogo(null);
    setDefaultTeamFields([
      { field: 'Team email', type: 'Email', required: true },
      { field: 'Team phone', type: 'Phone', required: true },
      { field: 'Hometown', type: 'Text', required: false },
    ]);
    setCustomTeamFields([]);
    setCatchRows([{ species: '', scoring: 'Catch & Release', points: 0 }]);
    setIsSubmitting(false);
    setIsSubmitted(false);
    props.close();
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    if (activeTab < 5) setActiveTab((prev) => prev + 1);
  };

  const handleBack = () => {
    if (activeTab > 0) setActiveTab((prev) => prev - 1);
  };

  return (
    <Dialog
      open={props.status}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      disableEscapeKeyDown={false}
    >
      <DialogTitle>
        Create New Event
        <IconButton onClick={handleClose} style={{ float: 'right' }}>
          <CloseIcon color="primary" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="event creation tabs" variant="fullWidth">
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Teams" {...a11yProps(1)} />
          <Tab label="Catches" {...a11yProps(2)} />
          <Tab label="Registration" {...a11yProps(3)} />
          <Tab label="Pots" {...a11yProps(4)} />
          <Tab label="Payment" {...a11yProps(5)} />
        </Tabs>

        {/* General Tab */}
        <TabPanel value={activeTab} index={0}>
          <h3>General Settings</h3>
          <p>Customize how you want your event website to appear.</p>
          <br/>
          <Stack spacing={2}>
            <TextField
              label="Event Title (e.g. Legends Fishing Tournament 2024)"
              value={eventTitle}
              onChange={(e) => setEventTitle(e.target.value)}
              fullWidth
              required
            />
            <FormControl fullWidth>
              <InputLabel>Tier</InputLabel>
              <Select
                label="Tier"
                value={tier}
                onChange={(e) => setTier(e.target.value)}
                fullWidth
                required
              >
                <MenuItem value="Tier 1">Tier 1</MenuItem>
                <MenuItem value="Tier 2">Tier 2</MenuItem>
                <MenuItem value="Tier 3">Tier 3</MenuItem>
                <MenuItem value="Tier 4">Tier 4</MenuItem>
              </Select>
            </FormControl>

            {/* Background Color Picker */}
            <Box>
              <InputLabel>Background Color</InputLabel>
              <Button
                variant="outlined"
                onClick={handleOpenDialog(setBackgroundColorDialogOpen)}
                sx={{
                  backgroundColor: backgroundColor,
                  color: backgroundColor === '#ffffff' ? 'black' : 'white',
                  textTransform: 'none',
                }}
                required
              >
                {backgroundColor}
              </Button>
              <Dialog open={backgroundColorDialogOpen} onClose={handleCloseDialog(setBackgroundColorDialogOpen)}>
                <DialogTitle>
                  Select Background Color
                  <IconButton onClick={handleCloseDialog(setBackgroundColorDialogOpen)} style={{ float: 'right' }}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <SketchPicker
                    color={backgroundColor}
                    onChangeComplete={(color) => setBackgroundColor(color.hex)}
                  />
                </DialogContent>
              </Dialog>
            </Box>

            {/* Headline Text Color Picker */}
            <Box>
              <InputLabel>Headline Text Color</InputLabel>
              <Button
                variant="outlined"
                onClick={handleOpenDialog(setHeadlineTextColorDialogOpen)}
                sx={{
                  backgroundColor: headlineTextColor,
                  color: headlineTextColor === '#ffffff' ? 'black' : 'white',
                  textTransform: 'none',
                }}
              >
                {headlineTextColor}
              </Button>
              <Dialog open={headlineTextColorDialogOpen} onClose={handleCloseDialog(setHeadlineTextColorDialogOpen)}>
                <DialogTitle>
                  Select Headline Text Color
                  <IconButton onClick={handleCloseDialog(setHeadlineTextColorDialogOpen)} style={{ float: 'right' }}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <SketchPicker
                    color={headlineTextColor}
                    onChangeComplete={(color) => setHeadlineTextColor(color.hex)}
                  />
                </DialogContent>
              </Dialog>
            </Box>

            {/* Subheadline Text Color Picker */}
            <Box>
              <InputLabel>Subheadline Text Color</InputLabel>
              <Button
                variant="outlined"
                onClick={handleOpenDialog(setSubheadlineTextColorDialogOpen)}
                sx={{
                  backgroundColor: subheadlineTextColor,
                  color: subheadlineTextColor === '#ffffff' ? 'black' : 'white',
                  textTransform: 'none',
                }}
              >
                {subheadlineTextColor}
              </Button>
              <Dialog open={subheadlineTextColorDialogOpen} onClose={handleCloseDialog(setSubheadlineTextColorDialogOpen)}>
                <DialogTitle>
                  Select Subheadline Text Color
                  <IconButton onClick={handleCloseDialog(setSubheadlineTextColorDialogOpen)} style={{ float: 'right' }}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <SketchPicker
                    color={subheadlineTextColor}
                    onChangeComplete={(color) => setSubheadlineTextColor(color.hex)}
                  />
                </DialogContent>
              </Dialog>
            </Box>

            {/* Date Pickers for Event Start and End Dates */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Event Start Date"
                value={eventStartDateTime}
                onChange={(date) => setEventStartDateTime(date)}
                disablePast
                fullWidth
                required
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Event End Date"
                value={eventEndDateTime}
                onChange={(date) => setEventEndDateTime(date)}
                disablePast
                fullWidth
                required
              />
            </LocalizationProvider>

            {/* Text Field for Homepage Subheadline */}
            <TextField
              label="Homepage Message(s)"
              value={homepageSubheadline}
              onChange={(e) => setHomepageSubheadline(e.target.value)}
              multiline
              rows={4}
              fullWidth
            />

            {/* File Input for Event Logo */}
            <Box>
              <InputLabel>Event Logo</InputLabel>
              <input type="file" onChange={handleLogoUpload} id={`event-logo-upload-button`} />
              <br/>
              <br/>
              {eventLogo && (
                <Box display="flex" alignItems="center">
                  <img
                    src={URL.createObjectURL(eventLogo)}
                    alt="Event Logo"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px' }}
                  />
                  <IconButton size="small" onClick={handleRemoveLogo}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Box>

          </Stack>
        </TabPanel>

        {/* Teams Tab */}
        <TabPanel value={activeTab} index={1}>
          <h3>Team Settings</h3>
          <p>Define what information you want from each team that registers.</p>
          <br/>
          { tier === "Tier 1" ? (
            <p><strong>Max number of teams allowed ({tier}):</strong> 50</p>
          ):(
            <p><strong>Max number of teams allowed ({tier}):</strong> Unlimited</p>
          )}
          <br/>

          {/* Default Rows */}
          {defaultTeamFields.map((field, index) => (
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={2}
              sx={{ mt: 2, borderBottom: isMobile ? '1px solid #ddd' : 'none', pb: isMobile ? 2 : 0 }}
              key={`default-${index}`}
            >
              <TextField
                label="Field Name"
                value={field.field}
                onChange={(e) => updateDefaultTeamField(index, 'field', e.target.value)}
                fullWidth
              />
              <FormControl fullWidth>
                <InputLabel>Data type</InputLabel>
                <Select
                  label="Data type"
                  value={field.type}
                  onChange={(e) => updateDefaultTeamField(index, 'type', e.target.value)}
                >
                  <MenuItem value="Text">Text</MenuItem>
                  <MenuItem value="Number">Number</MenuItem>
                  <MenuItem value="Email">Email</MenuItem>
                  <MenuItem value="Phone">Phone</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={<Checkbox checked={field.required} onChange={(e) => updateDefaultTeamField(index, 'required', e.target.checked)} />}
                label="Required?"
              />
              {isMobile ? (
                <Button
                  onClick={() => handleRemoveDefaultTeamField(index)}
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ alignSelf: 'flex-start' }}  // Align button to top when in column mode
                >
                  Delete Field
                </Button>
              ) : (
                <IconButton onClick={() => handleRemoveDefaultTeamField(index)} size="small">
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>
          ))}

          {/* Custom Rows */}
          {customTeamFields.map((field, index) => (
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={2}
              sx={{ mt: 2, borderBottom: isMobile ? '1px solid #ddd' : 'none', pb: isMobile ? 2 : 0 }}
              key={`custom-${index}`}
            >
              <TextField
                label="Field Name"
                value={field.field}
                onChange={(e) => updateCustomTeamField(index, 'field', e.target.value)}
                fullWidth
              />
              <FormControl fullWidth>
                <InputLabel>Data type</InputLabel>
                <Select
                  label="Data type"
                  value={field.type}
                  onChange={(e) => updateCustomTeamField(index, 'type', e.target.value)}
                >
                  <MenuItem value="Text">Text</MenuItem>
                  <MenuItem value="Number">Number</MenuItem>
                  <MenuItem value="Email">Email</MenuItem>
                  <MenuItem value="Phone">Phone</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={<Checkbox checked={field.required} onChange={(e) => updateCustomTeamField(index, 'required', e.target.checked)} />}
                label="Required?"
              />
              {isMobile ? (
                <Button
                  onClick={() => handleRemoveCustomTeamField(index)}
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ alignSelf: 'flex-start' }}  // Align button to top when in column mode
                >
                  Delete Field
                </Button>
              ) : (
                <IconButton onClick={() => handleRemoveCustomTeamField(index)} size="small">
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>
          ))}

          <br />
          <Button onClick={addCustomTeamField} variant="outlined">Add Team Field</Button>
        </TabPanel>

        {/* Catches Tab */}
        <TabPanel value={activeTab} index={2}>
          <h3>Catch Settings</h3>
          <p>Define your event species and preferred scoring system.</p>
          <br />
          {tier === "Tier 1" ? (
            <p><strong>Max number of catches allowed ({tier}):</strong> 250</p>
          ) : (
            <p><strong>Max number of catches allowed ({tier}):</strong> Unlimited</p>
          )}
          <br />

          {/* Display Rows for Catches */}
          {catchRows.map((catchRow, index) => (
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={2}
              sx={{ mt: 2, borderBottom: isMobile ? '1px solid #ddd' : 'none', pb: isMobile ? 2 : 0 }}
              key={`catch-${index}`}
            >
              {/* Species Name */}
              <TextField
                label="Species"
                value={catchRow.species}
                onChange={(e) => handleCatchAttributeChange(e, index, 'species')}
                fullWidth
              />

              {/* Scoring Select Menu */}
              <FormControl fullWidth>
                <InputLabel>Scoring</InputLabel>
                <Select
                  label="Scoring"
                  value={catchRow.scoring}
                  onChange={(e) => handleCatchAttributeChange(e, index, 'scoring')}
                >
                  <MenuItem value="Catch & Release">Catch & Release</MenuItem>
                  <MenuItem value="Weight Only">Weight Only</MenuItem>
                  <MenuItem value="Size Only">Size Only</MenuItem>
                  <MenuItem value="Weight & Size">Weight & Size</MenuItem>
                </Select>
              </FormControl>

              {/* Points Field (Only visible if type is 'Catch & Release') */}
              {catchRow.scoring === 'Catch & Release' ? (
                <TextField
                  label="Points"
                  type="number"
                  value={catchRow.points}
                  onChange={(e) => handleCatchAttributeChange(e, index, 'points')}
                  fullWidth
                />
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
                  <p>-</p>
                </Box>
              )}

              {/* Delete Button */}
              {isMobile ? (
                <Button
                  onClick={() => handleRemoveCatchRow(index)}
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ alignSelf: 'flex-start' }}
                >
                  Delete Row
                </Button>
              ) : (
                <IconButton onClick={() => handleRemoveCatchRow(index)} size="small">
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>
          ))}

          <br />
          {/* Add Button to add a new row for catches */}
          <Button onClick={handleAddCatchRow} variant="outlined">Add Species</Button>
        </TabPanel>

        {/* Registration Tab */}
        <TabPanel value={activeTab} index={3}>
          <h3>Registration Settings</h3>
          <p>Integrated online registration is available for Tier 3 and Tier 4 events.</p>
        </TabPanel>

        {/* Pots Tab */}
        <TabPanel value={activeTab} index={4}>
          <h3>Pot Settings</h3>
          <p>Integrated betting pots (e.g. calcutta) are available for Tier 3 and Tier 4 events.</p>
        </TabPanel>

        {/* Payment Tab */}
        <TabPanel value={activeTab} index={5}>
          <h3>Payment</h3>
          <p>Payment settings and configurations will be managed here...</p>
          <p>Implement any user add-ons or company-specific upsell fields as needed.</p>
          <br/>
          <Button variant="contained" color="primary" onClick={handleCreateEventAsAdmin}>
            Create Event as Admin (Dev Only)
          </Button>
        </TabPanel>

        {/* Navigation Buttons */}
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
          <Button variant="contained" color="secondary" onClick={handleBack} disabled={activeTab === 0}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext} disabled={activeTab === 5}>
            Next
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CreateEventModal;

