import { Outlet, useLocation } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import { useState } from "react";
import { colorConfig, sizeConfig } from "../config/config";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import Authenticate from "../components/authenticate/Authenticate";
import AnimatedPage from "../pages/AnimatedPage";

const MainLayout = () => {
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const location = useLocation();
  const currentUser = JSON.parse(window.localStorage.getItem('user'));

  // Determine the current page title based on the URL path
  const pageTitleMap = {
    "/dashboard": "Dashboard",
    "/profile": "Profile",
    "/statistics": "Statistics",
  };
  const pageTitle = pageTitleMap[location.pathname] || "Dashboard"; // Default to "Dashboard"

  return currentUser ? (
    <AnimatedPage>
      <Box sx={{ display: "flex" }}>
        <Topbar sidebarExpanded={sidebarExpanded} pageTitle={pageTitle} />
        <Box
          component="nav"
          sx={{
            width: sidebarExpanded ? sizeConfig.sidebar.width : "70px",
            flexShrink: 0,
          }}
        >
          <Sidebar sidebarExpanded={sidebarExpanded} setSidebarExpanded={setSidebarExpanded} />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: `calc(100% - ${sidebarExpanded ? sizeConfig.sidebar.width : "70px"})`,
            minHeight: "100vh",
            backgroundColor: colorConfig.mainBg,
            transition: "width 0.3s",
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </AnimatedPage>
  ) : (
    <AnimatedPage>
      <Authenticate />
    </AnimatedPage>
  );
};

export default MainLayout;
