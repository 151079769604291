import React, { useState } from 'react';
import { Grid, Paper, Avatar, TextField, Button, Typography, Link } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ToggleSliderButton from '../buttons/ToggleSliderButton';
import AnimatedPage from '../../pages/AnimatedPage';
import { sendPasswordResetEmail, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'react-toastify';
import { auth } from '../../firebase';
import { colorConfig } from '../../config/config';

const Authenticate = () => {
  const paperStyle = { padding: 20, height: 'auto', width: 330, textAlign: 'center' };  // Center text within the Paper component
  const avatarStyle = { backgroundColor: colorConfig.sidebar.bg, margin: 'auto' };
  const buttonStyle = { backgroundColor: colorConfig.sidebar.bg };
  const gridContainerStyle = { 
    minHeight: '100vh', 
    backgroundColor: colorConfig.authenticateBg, // Light grey background for the entire screen
  };
  const navigate = useNavigate();

  const [authenticationChoice, setAuthenticationChoice] = useState('Login');
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [organization, setOrganization] = useState("");
  const [phone, setPhone] = useState("");
  const [resetPasswordMode, setResetPasswordMode] = useState(false);

  // HELPERS
  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validatePhoneNumber = (phone) => {
    return /^\(?([0-9]{3})\)?[-.?]?([0-9]{3})[-.?]?([0-9]{4})$/.test(phone);
  };

  const convertToCamelCase = (string) => string.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
    index === 0 ? word.toLowerCase() : word.toUpperCase()
  ).replace(/\s+/g, '');

  // AUTHENTICATION
  const handleCreateAccount = () => {

    if (!organization) {
      toast.error("Please enter an organization / event name.");
      return;
    }
    
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email.");
      return;
    }

    if (phone && !validatePhoneNumber(phone)) {
      toast.error("Please enter a valid phone number.");
      return;
    }

    if (!password || password.length < 6) {
      toast.error("Password must be at least 6 characters.");
      return;
    }

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const userData = {
          userId: user.uid,
          username: convertToCamelCase(organization),
          email: user.email,
          dateProfileCreated: new Date().toISOString(),
          phone: phone || '',
        };

        // Call the new API endpoint to create the user profile in Firestore
        fetch('/api/create_new_user_profile', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(userData)
        })
          .then(response => response.json())
          .then(data => {
            if (data.success) {
              toast.success("Account created successfully! Logging you in...", { autoClose: 2000 });
              window.localStorage.setItem('user', JSON.stringify(user));
              setTimeout(() => navigate('/dashboard'), 2000);
            } else {
              toast.error("Failed to create profile in Firestore: " + data.message);
            }
          })
          .catch(error => {
            toast.error("Error creating profile in Firestore: " + error.message);
          });
      })
      .catch((error) => {
        toast.error("Error creating account: " + error.message);
      });
  };

  const handleLogin = () => {
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email.");
      return;
    }
    if (!password) {
      toast.error("Please enter your password.");
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        toast.success("Login successful! Redirecting...", { autoClose: 2000 });
        window.localStorage.setItem('user', JSON.stringify(user));
        setTimeout(() => {
          navigate('/dashboard'); // Redirect to /dashboard after account creation
        }, 2000);
      })
      .catch((error) => {
        if (error.code === 'auth/wrong-password') {
          toast.error("Incorrect password. Please try again.");
        } else if (error.code === 'auth/user-not-found') {
          toast.error("Email not found. Please sign up first.");
        } else {
          toast.error("Error logging in: " + error.message);
        }
      });
  };

  const handleResetPassword = () => {
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email.");
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("Password reset email sent! Check your inbox.");
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {
          toast.error("No account found for this email. Please sign up first.");
        } else {
          toast.error("Error sending password reset email: " + error.message);
        }
      });
  };

  return (
    <AnimatedPage>
        <Grid container justifyContent="center" alignItems="center" style={gridContainerStyle}>
          <Paper variant='elevation' elevation={10} style={paperStyle}>
            <Grid align='center'>
              <Avatar style={avatarStyle}><LockOutlined /></Avatar>
            </Grid>
            <br/>
            <h2 style={{ textAlign: 'center' }}>Custom Tournament Solutions</h2>
            <br/>
            <ToggleSliderButton choice={authenticationChoice} choiceList={["Login", "Create Account"]} setAlignment={setAuthenticationChoice} />
            <br/>
            <br/>
            {resetPasswordMode ? (
              <>
                <Typography variant="body1" align="center">Enter your email to receive a password reset link.</Typography>
                <br/>
                <TextField label='Email' placeholder="Email" fullWidth required onChange={(e) => setEmail(e.target.value)} />
                <br/>
                <br/>
                <Button style={buttonStyle} variant="contained" fullWidth onClick={handleResetPassword}>Send Reset Link</Button>
                <br/>
                <Link component="button" variant="body2" onClick={() => setResetPasswordMode(false)} style={{ marginTop: 10 }}>Back to {authenticationChoice}</Link>
              </>
            ) : (
              <>
                {authenticationChoice === "Login" && (
                  <>
                    <TextField label='Email' placeholder="Email" fullWidth required onChange={(e) => setEmail(e.target.value)} />
                    <br/>
                    <br/>
                    <TextField label='Password' placeholder="Password" fullWidth required type='password' onChange={(e) => setPassword(e.target.value)} />
                    <br/>
                    <br/>
                    <Button style={buttonStyle} variant="contained" fullWidth onClick={handleLogin}>Sign In</Button>
                    <br/>
                    <Link component="button" variant="body2" onClick={() => setResetPasswordMode(true)} style={{ marginTop: 10 }}>Forgot Password?</Link>
                  </>
                )}

                {authenticationChoice === "Create Account" && (
                  <>
                    <TextField label='Organization / event name' placeholder="Organization" fullWidth required onChange={(e) => setOrganization(e.target.value)} />
                    <br/>
                    <br/>
                    <TextField label='Phone (Optional)' placeholder="Phone (Optional)" fullWidth onChange={(e) => setPhone(e.target.value)} />
                    <br/>
                    <br/>
                    <TextField label='Email' placeholder="Email" fullWidth required onChange={(e) => setEmail(e.target.value)} />
                    <br/>
                    <br/>
                    <TextField label='Password (min. 6 chars)' placeholder="Password (min. 6 chars)" fullWidth required type='password' onChange={(e) => setPassword(e.target.value)} />
                    <br/>
                    <br/>
                    <Button style={buttonStyle} variant="contained" fullWidth onClick={handleCreateAccount}>Create Account</Button>
                  </>
                )}
              </>
            )}
          </Paper>
        </Grid>
    </AnimatedPage>
  );
}

export default Authenticate;
