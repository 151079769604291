export const sizeConfig = {
  sidebar: {
    width: "300px"
  }
};

export const colorConfig = {
  sidebar: {
    bg: "#233044",
    color: "#eeeeee",
    hoverBg: "#1e293a",
    activeBg: "#1e253a"
  },
  topbar: {
    bg: '#ffffff',
    color: "#000"
  },
  // mainBg: colors.grey["100"],
  mainBg:'#ffffff',
  authenticateBg: 'grey',
};

