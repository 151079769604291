import { Navigate } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import DashboardPage from "../pages/DashboardPage";
import ProfilePage from '../pages/ProfilePage';
import StatisticsPage from "../pages/StatisticsPage";
import ErrorPage from "../pages/ErrorPage";
import Authenticate from "../components/authenticate/Authenticate";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BarChartIcon from '@mui/icons-material/BarChart';
import EventIcon from '@mui/icons-material/Event';
import LogoutIcon from '@mui/icons-material/Logout';

// Private Route Component to protect routes
const PrivateRoute = ({ element }) => {
  const currentUser = JSON.parse(window.localStorage.getItem('user'));
  return currentUser ? element : <Navigate to="/" replace />;
};

// Routes definition
export const appRoutes = [
  {
    path: '/',
    element: <Authenticate />, // Default to the Authenticate component
    errorElement: <ErrorPage />,
  },
  {
    path: '/profile',
    element: <PrivateRoute element={<MainLayout />} />, // Use PrivateRoute for MainLayout
    children: [
      { index: true, element: <ProfilePage /> },
    ],
    errorElement: <ErrorPage />,
    state: 'profile',
    sidebarProps: {
      displayText: "Profile",
      icon: <ManageAccountsIcon />
    }
  },
  {
    path: '/dashboard',
    element: <PrivateRoute element={<MainLayout />} />, // Use PrivateRoute for MainLayout
    children: [
      { index: true, element: <DashboardPage /> },
    ],
    errorElement: <ErrorPage />,
    state: "dashboard",
    sidebarProps: {
      displayText: "My Events",
      icon: <EventIcon />
    }
  },
  {
    path: '/statistics',
    element: <PrivateRoute element={<MainLayout />} />, // Use PrivateRoute for MainLayout
    children: [
      { index: true, element: <StatisticsPage /> },
    ],
    errorElement: <ErrorPage />,
    state: "statistics",
    sidebarProps: {
      displayText: "Statistics",
      icon: <BarChartIcon />
    }
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
];

// export const sidebarRoutes = [
//   {
//     path: "/profile",
//     element: <PrivateRoute element={<ProfilePage />} />,
//     state: "profile",
//     sidebarProps: {
//       displayText: "Profile",
//       icon: <ManageAccountsIcon />
//     }
//   },
//   {
//     path: "/dashboard",
//     element: <PrivateRoute element={<DashboardPage />} />,
//     state: "installation",
//     sidebarProps: {
//       displayText: "My Events",
//       icon: <EventIcon />
//     }
//   },
//   {
//     path: "/statistics",
//     element: <PrivateRoute element={<StatisticsPage />} />,
//     state: "statistics",
//     sidebarProps: {
//       displayText: "Statistics",
//       icon: <BarChartIcon />
//     }
//   },
//   {
//     path: "/logout",
//     element: <ErrorPage />, // Placeholder for logout functionality
//     state: "logout",
//     sidebarProps: {
//       displayText: "Logout",
//       icon: <LogoutIcon />
//     }
//   },
// ];

